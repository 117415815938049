/**
 * Rounds a number to two digits after the period dot or null, if the input is not a number
 * @param n number to round
 */
export function roundTwo(n) {
    if (typeof n === "number" && !isNaN(n)) {
        return Math.round(n * Math.pow(10, 2)) / Math.pow(10, 2);
    }
    else {
        return null;
    }
}
export function sumIgnoreNonNumbers(values) {
    return values
        .filter((n) => typeof n === "number")
        .reduce((acc, curr) => acc + curr, 0);
}
