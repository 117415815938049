import { sumIgnoreNonNumbers } from "./utils";
/**
 * Calculate the expected effort for a given workpackage
 */
export function calculateE(workPackage, r) {
    const { a, b, c } = workPackage;
    if (typeof a !== "number" ||
        a === null ||
        typeof b !== "number" ||
        b === null) {
        throw new TypeError("@calculateE(): At least a and b must be numbers");
    }
    return typeof c !== "number" || c === null
        ? (a + b) / 2
        : (a + r * c + b) / 6;
}
/**
 * Calculate the variance of the expected effort for a given workpackage
 */
export function calculateV(workPackage, u) {
    const { a, b } = workPackage;
    if (typeof a !== "number" || typeof b !== "number") {
        throw new TypeError("@calculateV(): a and b must be numbers");
    }
    return ((b - a) * (b - a)) / u ** 2;
}
/**
 * Calculate the standard deviation of the expected effort for a given workpackage
 */
export function calculateS(workPackage, u) {
    const { a, b } = workPackage;
    if (typeof a !== "number" || typeof b !== "number") {
        throw new TypeError("@calculateS(): a and b must be numbers");
    }
    return (b - a) / u;
}
export function calculateSSum(workPackages, u) {
    return Math.sqrt(sumIgnoreNonNumbers(workPackages
        .filter(({ a, b }) => typeof a === "number" && typeof b === "number")
        .map((workPackage) => calculateS(workPackage, u) ** 2)));
}
export function calculateESum(workPackages, r) {
    return sumIgnoreNonNumbers(workPackages
        .filter(({ a, b }) => typeof a === "number" && typeof b === "number")
        .map((workPackage) => calculateE(workPackage, r)));
}
export function calculateVSum(workPackages, u) {
    return sumIgnoreNonNumbers(workPackages
        .filter(({ a, b }) => typeof a === "number" && typeof b === "number")
        .map((workPackage) => calculateV(workPackage, u)));
}
