<script lang="ts">
    import { calculateE, calculateS, calculateV } from "./estimator";
    import { roundTwo } from "./utils";

    export let a: number
    export let b: number
    export let c: number;
    export let r: number = NaN;
    export let u: number = NaN;
    $: E = ( typeof a !== "number" || typeof b !== "number") ? null : calculateE({a,b,c}, r)
    $: S = ( typeof a !== "number" || typeof b !== "number") ? null : calculateS({a,b,c}, u)
    $: V = ( typeof a !== "number" || typeof b !== "number") ? null : calculateV({a,b,c}, u)
    $: SEPercent = (typeof S !== 'number' || typeof E !== 'number') ? null : (S/E)*100

    function hideNull(input: any|null): string {
        return input === null ? "-" : ""+input;
    }
</script>

<tr>
    <td class="tg-0lax">
        <input type="number" class="a-input" bind:value="{a}">
    </td>
    <td class="tg-0lax">
        <input type="number" class="c-input" bind:value="{c}">
    </td>
    <td class="tg-0lax">
        <input type="number" class="c-input"  bind:value="{b}">
    </td>
    <td class="tg-0lax">{hideNull(roundTwo(E))}</td>
    <td class="tg-0lax">{hideNull(roundTwo(S))}</td>
    <td class="tg-0lax">{hideNull(roundTwo(SEPercent))}%</td>
    <td class="tg-0lax">{hideNull(roundTwo(V))}</td>
</tr>