<script lang="ts">
    import type { WorkPackage } from "./estimator";
    import { calculateSSum, calculateESum, calculateVSum } from './estimator';
    import { roundTwo, sumIgnoreNonNumbers } from "./utils";

    export let workPackages: WorkPackage[]
    export let r: number = NaN;
    export let u: number = NaN;
</script>
<tr>
    <td class="tg-0lax">
        ∑ {sumIgnoreNonNumbers(workPackages.map(workPackage => workPackage.a))}
    </td>
    <td class="tg-0lax">
        ∑ {sumIgnoreNonNumbers(workPackages.map(workPackage => workPackage.c))}
    </td>
    <td class="tg-0lax">
        ∑ {sumIgnoreNonNumbers(workPackages.map(workPackage => workPackage.b))}
    </td>
    <td class="tg-0lax">
        ∑ {roundTwo(calculateESum(workPackages, r))}
    </td>
    <td class="tg-0lax">
        {roundTwo(calculateSSum(workPackages, u))}
    </td>
    <td class="tg-0lax">
    </td>
    <td class="tg-0lax">
        ∑ {roundTwo(calculateVSum(workPackages, u))}
    </td>
</tr>