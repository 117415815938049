<script lang="ts">
    import type { WorkPackage } from "./estimator";

    export let workPackages: WorkPackage[]
    export let u: number;
    $: nonEmptyWorkPackages = workPackages.filter(wp => wp.a !== null && wp.b !== null)
    $: lastElementIndex = nonEmptyWorkPackages.length - 1;
</script>

<style>
    div.formula-s {
        padding-bottom: 10px;
    }
</style>

<div class="formula-s">
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mo>S(x)</mo>
        <mo>=</mo>
        <msqrt>
            {#each nonEmptyWorkPackages as workPackage, i}
                <msup>
                    <mrow>
                        <mo>(</mo>
                        <mfrac>
                            <mrow>
                                <mo> {workPackage.b} </mo>
                                <mo> - </mo>
                                <mo> {workPackage.a} </mo>
                            </mrow>
                            <mo> {u} </mo>
                        </mfrac>
                        <mo>)</mo>
                    </mrow>
                    <mn>2</mn>
                </msup>
                {#if i < lastElementIndex}
                <mo> + </mo>
                {/if}
            {/each}
        </msqrt>
    </mrow>
    </math>
</div>