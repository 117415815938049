<script lang="ts">
    import type { WorkPackage } from "./estimator";

    export let workPackages: WorkPackage[]
    export let r: number;
    $: nonEmptyWorkPackages = workPackages.filter(wp => wp.a !== null && wp.b !== null)
    $: lastElementIndex = nonEmptyWorkPackages.length - 1;
</script>
<style>
    div.formula-e {
        padding-top: 10px;
    }
</style>

<div class="formula-e">
    <math xmlns="http://www.w3.org/1998/Math/MathML">
    <mrow>
        <mo>E(x)</mo>
        <mo>=</mo>
        {#each nonEmptyWorkPackages as workPackage, i}
            <mfrac>
                <mrow>
                    <mo> {workPackage.b} </mo>
                    <mo> + </mo>
                    {#if typeof workPackage.c === 'number'}
                        <mo> {r} </mo>
                        <mo> ⋅ </mo>
                        <mo> {workPackage.c} </mo>
                        <mo> + </mo>
                    {/if}
                    <mo> {workPackage.a} </mo>
                </mrow>
                <mrow>
                    <mo>
                        {typeof workPackage.c === 'number' ? '6' : '2'}
                    </mo>
                </mrow>
            </mfrac>
            {#if i < lastElementIndex}
            <mo> + </mo>
            {/if}
        {/each}
    </mrow>
    </math>
</div>