<script lang="ts">
    import { element } from "svelte/internal";
import type { WorkPackage } from "./estimator";
import FormulaE from "./FormulaE.svelte";
import FormulaS from "./FormulaS.svelte";
    import RowWorkItem from "./RowWorkItem.svelte";
    import SumRow from "./SumRow.svelte";

    const NUMBER_OF_WORKPACKAGES = 8;

    let workPackages: WorkPackage[] = []
    export let r: number = 4;
    export let u: number = 6;
    for (let i = 0; i <= NUMBER_OF_WORKPACKAGES; i++) {
        workPackages.push({a: null, b:null, c: null});
    }
    $: dataJson = JSON.stringify(workPackages.filter(({a,b,c}) => a !== null || b !== null || c !== null), null, 2)
    let jsonPasteArea = "";


</script>

<main>
    r:
    <input type="number" bind:value={r}>
    u:
    <input type="number" bind:value={u}>
    <br>
    <button on:click={() => {r = 4; u = 6}}>
        Verteilung "Beta (PERT)" benutzen (Vorlesungs-Standard)
    </button>
    <button on:click={() => {r = 6; u = 6}}>
        Verteilung "Beta (p=q=4 (kubisch))" benutzen
    </button>
    <br>
	<table class="tg">
        <thead>
            <th class="tg-0lax">a</th>
            <th class="tg-0lax">c</th>
            <th class="tg-0lax">b</th>
            <th class="tg-0lax">E</th>
            <th class="tg-0lax">S</th>
            <th class="tg-0lax">S/E</th>
            <th class="tg-0lax">V</th>
        </thead>
        <tbody>
            {#each workPackages as workPackage, i (i)}
                <RowWorkItem bind:a={workPackage.a} bind:b={workPackage.b} bind:c={workPackage.c} u={u} r={r}></RowWorkItem>
            {/each}
            <SumRow {workPackages} u={u} r={r}></SumRow>
        </tbody>
    </table>
    <button on:click={() => {workPackages = [...workPackages, {a: null, b: null, c: null}];}}>Zeile hinzufügen</button>

    <FormulaE workPackages={workPackages} r={r}/>
    <br>
    <FormulaS workPackages={workPackages} u={u}/>

    <details>
        <summary>
            Debug: Show Input as JSON
        </summary>
        <pre style="border: 1px solid;">
            {dataJson}
        </pre>
    </details><details>
        <summary>
            Debug: Paste Input as JSON
        </summary>
        <textarea bind:value="{jsonPasteArea}" on:input={() => {
            if (jsonPasteArea.trim().length === 0) return
            try {
                const decoded = JSON.parse(jsonPasteArea.trim());
                if (!Array.isArray(decoded))
                    throw TypeError("Input must be an array")
                for (const elem of decoded) {
                    if (typeof elem.a !== 'number' && elem.a !== null)
                        throw TypeError("An 'a' attribute was neither number not null");
                    if (typeof elem.b !== 'number' && elem.b !== null)
                        throw TypeError("An 'b' attribute was neither number not null");
                    if (typeof elem.c !== 'number' && elem.c !== null)
                        throw TypeError("An 'c' attribute was neither number not null");
                }
                workPackages = [...decoded, {a: null, b: null, c: null}];
                jsonPasteArea = "";
            } catch (error) {
                alert(error.toString())
            }
        }}/>
    </details>
</main>

<footer>
    <a href="https://gitlab.fachschaften.org/oh14-dev/swk-project-effort-estimation">
  SourceCode
  </a>
  –
  <a href="https://zohren.xyz/datenschutz.html">Datenschutz</a>
  –
  Powered by
  <a href="https://svelte.dev/">Svelte</a>
</footer>